/* fonts */
$font: 'proxima-nova', sans-serif;

/* colours */
$primary: #A1D2DB;
$lighter: lighten(desaturate($primary, 6.15), 15.29);
$lightest: lighten(desaturate(adjust-hue($primary, -9), 22.88), 20.98);
$shade: darken(desaturate(adjust-hue($primary, -1), 24.04), 15.49); /* darker */

$txt: #707070;
$light-grey: #D1D3D4;
$mid-grey: #A4A4A4;

/* timing */
$quick: 0.3s;
$mid: 0.5s;
$long: 1s;

/* common functions */
@mixin button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 1rem;
    line-height: 1;
    text-decoration: none;
    padding: 15px 30px;
    max-width: 100%;
    color: #FFF;
    background-color: $primary;
    border: none;
    box-sizing: border-box;
    border-radius: 3px;
    transition: background-color $quick ease-in-out;
    cursor: pointer;
    margin: 1px;
    &:hover {
        background-color: $shade;
    }
}
@mixin button_reverse {
    @include button;
    color: $primary;
    background-color: #FFF;
    &:hover {
        color: $shade;
        background-color: $lighter;
    }
}

@mixin darken_before($colour: $light-grey, $pos: relative) {
    position: $pos;
    &::before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        /** doesn't support blend mode **/
        background-color: rgba(#000, 0.2);
        /** does support blend mode - different colour **/
        @supports(mix-blend-mode:multiply){
            background-color: $colour;
            mix-blend-mode: multiply;
        }
        z-index: 1;
    }
}

@mixin square {
    position: relative;
    box-sizing: border-box;
    &::before { /* responsive square */
        content:"";
        display: block;
        position: relative;
        width: 100%;
        padding-top: 100%;
    }
}

@mixin dots {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid #FFF;
    border-radius: 50%;
    margin: 0px 10px;
    transition: background-color $quick ease;
    &:hover {
        background-color: $lighter;
    }
    &.active {
        background-color: #FFF;
        &:hover {
            background-color: #FFF;
        }
    }
}
@mixin dots_dark {
    @include dots;
    border-color: $primary;
    margin: 0px 5px;
    &.active {
        background-color: $primary;
        &:hover {
            background-color: $primary;
        }
    }
}