@import 'vars';

html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}


fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
input,
select,
textarea,
button {
    border-radius:0; /* reset safari round corners */
}


/* ==========================================================================
   Author's custom styles
   ========================================================================== */

body {
    padding:0;
    margin:0;
    font-size: 24px;
}

@mixin contentwrap {
    /* used in banner-navigation */
    position: relative;
    padding: 30px;
    margin: 0 auto;
    max-width: 1200px;
}
.contentwrap {
    @include contentwrap;
}
.contentwide { /* used in header and footer */
    position: relative;
    padding: 40px;
    box-sizing: border-box;
}

img {
    border:none;
}
a.logo {
    display: inline-block;
    width: 200px;
    height:auto;
}

@mixin flex_apart {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-evenly;
    justify-content: space-between;
    /* align = stretch default */
    box-sizing: border-box;
}

/** menu generic **/
nav {
    ul {
        list-style:none;
        font-size: 18px;
        padding: 0;
        margin: 0;
        &.home {
            @include flex_apart;
        }
    }
    > ul {
        @include flex_apart;
    }
    li {
        display: inline-flex; /* needed so link fills height */
        flex-direction: column;
        width: 30%;
        padding: 2% 0px;
        box-sizing: border-box;
        a {
            display: flex; /* needed so we can separate + reorder */
            flex-direction: column;
            justify-content: flex-start; /* top */
            color: inherit;
            text-decoration: none;
            > span {
                display: block;
                text-align: left;
                margin: 20px 0px;
                .subtitle {
                    display: block;
                    margin-bottom: 20px;
                }
            }
            &:hover {
                .menu-icon .hover {
                    opacity: 1;
                }
            }
        }
        .title {
            font-size: 2.2em;
            text-transform: capitalize;
            color: $shade;
        }
        &.top {
            > a .title {
                font-weight: bold;
            }
        }
        /* home nav only */
        .menu-icon {
            width: 100%;
            @include square;
            background-size: cover;
            background-position: center;
            align-self: flex-start;
        }
        .subtitle {
            color: $primary;
            font-size: 1.3em;
            text-transform: uppercase;
        }
        .page-teaser {
            color: $txt;
            font-size: 1em;
        }
    }
}
header,
footer,
.slide-menu {
    color: #FFF;
    font-size: 18px;
    nav {
        li {
            width: 48%;
            a {
                transition: underline $quick ease;
                padding: 2px 0px;
                > span {
                    margin: 0px;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            .title {
                color: inherit;
                font-size: 1em;
            }
            .subtitle {
                display: none;
            }
            &.top {
                margin: 10px 0px;
            }
            li {
                width: 100%;
            }
        }
    }
}
.flex,
.list {
    @include flex_apart;
}
.flex {
    > .square.icon {
        position: relative;
        display: inline-block;
        width: 60%;
        box-sizing: border-box;
        align-self: center;
    }
}
.col { /* half by default */
    display: inline-block;
    width: 48%;
    vertical-align: top;
    box-sizing: border-box;
    /* align at top */
    h1, h2, h3, h4, p {
        margin-top: 0px;
    }
}
header,
footer,
.about-pg,
.contact-details {
    a {
        text-decoration: none;
        color: inherit;
    }
}

/** form generic **/
form {
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    .half {
        @include flex_apart;
        align-items: flex-start;
        width: 100%;
        padding: 0px;
        box-sizing: border-box;
        /* flex margins don't collapse */
        margin-bottom: -5px;
        &:nth-of-type(2){
            margin-top: 5px;
        }
        p {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            box-sizing: border-box;
            /* flex margins don't collapse */
            margin: 5px 0px;
            &:nth-of-type(odd){
                padding-right: 0.5%;
            }
            &:nth-of-type(even){
                padding-left: 0.5%;
            }
        }
    }
    .form-group.row,
    p {
        position: relative;
        margin: 10px 0;
        span.intro {
            display: block;
            padding-top: 10px;
        }
    }
    /* button paragraph has more gap above */
    > p:last-of-type {
        margin-top: 1.5em;
    }
    button {
        @include button;
        min-width: 120px;
        cursor: pointer;
    }
}


input {
    height: 45px;
    line-height: 45px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}

label.float,
form .control-label {
    line-height: 1;
    padding-top: 15px;
    min-height: 45px;
}
input,
textarea,
select {
    text-align: left;
    padding-left: 20px;
    font-family: $font;
    color: inherit;
    font-size: 16px;
    padding-top: 10px;
    width: 100%;
    border: 1px solid $shade;
    box-sizing: border-box;
}
textarea {
    min-height: 150px;
}
@mixin label_txt {
    color: $shade;
    text-transform: capitalize;
}
select {
    padding-left: 15px;
    padding-top: 0px;
    height: 40px;
/* style invalid select like a label */
    &:invalid {
        @include label_txt;
    }
}
label.float,
form .control-label {
    position: absolute;
    left:0;
    @include label_txt;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
}
.focused,
.populated {
    label.float,
    label.control-label {
        line-height: 1;
        padding-top: 5px;
        font-size: 8px;
    }
}
.recaptcha-notice a {
    color: inherit;
}

/** site links **/
.site-link {
    display: inline-block;
    width: auto;
    margin: 5px;
    text-decoration: none;
    img {
        position: static;
        display: block;
        max-height: 30px;
    }
}
header,
footer,
.slide-menu {
    .site-link {
        img {
            max-height: 22px;
        }
    }
}

/* backgrounds */
.blue,
header {
    color: #FFF;
    background-color: $primary;
    h1, h2, h3, h4 {
        color: inherit;
    }
    .button {
        @include button_reverse;
    }
    form {
        color: #FFF;
        input {
            background-color: transparent;
            border-color: #FFF;
        }
        button {
            @include button_reverse;
        }
    }
    label.float {
        color: inherit;
    }
}
.tint1 {
    background-color: $lighter;
    h2.title {
        color: #FFF;
    }
}

div.icon,
.page-banner {
    position: relative;
    color: #FFF;
    background-color: $primary;
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
    }
}
@mixin banner_contentwrap {
    padding: 5% 30px;
    width: 1260px;
    max-width: 100%;
    z-index: 1; /* above overlay */
    margin: 0px auto;
    box-sizing: border-box;
}
@mixin banner-line {
    content:"";
    position: absolute;
    left: 130px;
    top: 100%;
    width: 2px;
    background-color: $primary;
    height: 10vw;
    z-index: 1;
}
.page-banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #FFF;
    padding-top: 230px; /* header top-bar */
    background-color: $mid-grey;
    width: 100%;
    min-height: 300px;
    box-sizing: border-box;
    @include darken_before;
    &:after { /* left border, on top of overlay */
        content:"";
        display: block;
        position: absolute;
        top: 0px;
        bottom: 0px;
        height: 100%;
        width: 20px;
        background-color: $primary;
        z-index: 1;
    }
    .contentwrap {
        @include banner_contentwrap;
        z-index: 1;
        .flex {
            position: relative;
            &:after {
                @include banner-line;
            }
            h1, h2 {
                font-size: 2em;
            }
        }
    }
    h1, h2, h3 {
        color: inherit;
    }
    img { /* page-graphic */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        z-index: 0;
    }
}
div {
    &.square {
        @include square;
        background-color: $mid-grey;
    }
    .hover {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba($primary, 0.53);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity $mid ease;
        .button {
            @include button_reverse;
            &:hover { /* undo hover state */
                color: $primary;
                background-color: #FFF;
            }
        }
    }
    &.icon {
        .fallback {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: $primary;
            background-size: 50%;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

/** banner generic **/
.banners {
    position: relative;
}
.banner-stage {
    position: relative;
    display: flex; /* stretch the banners */
    flex-direction: column;
    flex-grow: 1; /* fill height */
    padding-top: 30%;
    min-height: 400px;
    box-sizing: border-box;
}
.banner {
    @include darken_before($pos: absolute);
    top:0;
    left:0;
    right:0;
    /*bottom:0;*/
    width: 100%;
    /*height: 100%;*/
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    color: #FFF;
    background-color: $mid-grey;
    background-position:center;
    background-size:cover;
    text-decoration: none;
    padding-bottom: 90px; /* navigation */
    box-sizing: border-box;
    opacity: 0;
    z-index: 0;
    transition: opacity $mid ease;
    &.active {
        opacity: 1;
        z-index: 1;
    }
}
.banner-content {
     @include banner_contentwrap;
}
.banner-navigation {
    display: none;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    z-index: 1;
}
.banner-navigation-body {
    @include contentwrap;
    padding-top: 0px;
    padding-bottom: 60px;
    text-align: center;
}
.banner-nav-item {
    @include dots;
    > span {
        display: none;
    }
}

section {
    padding: 5% 0px;
    &.about-pg {
        padding-bottom: 0px;
    }
    &.feature {
        padding-top: 0px;
        padding-bottom: 8%;
    }
}
div.item {
    a {
        color: inherit;
        text-decoration: none;
        &:hover {
            .icon .hover {
                opacity: 1;
            }
        }
    }
}

/*============ header ============*/
header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: transparent;
    transition: background-color $mid linear;
    box-sizing: border-box;
    z-index: 100;
    .header-bar-top {
        position: relative;
        z-index: 1;
    }
    .top-slide {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        max-height: 0px;
        overflow: hidden;
        opacity: 0;
        transition: max-height $quick linear;
        z-index: 1; /* slide out under top bar */
        .contentwrap {
            padding-top: 0px;
        }
    }
    .col {
        display: inline-flex;
        flex-direction: column;
        width: 45%;
        .bottom {
            @include flex_apart;
            padding-top: 20px;
            margin-top: auto;
            border-top: 1px solid #FFF;
            > span,
            > a {
                display: inline-block;
            }
            > a {
                font-weight: bold;
                min-width: 48%;
                transition: underline $quick ease;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .list {
        display: inline-flex;
        width: 48%;
        box-sizing: border-box;
        .item {
            width: 48%;
        }
    }
}

#mobile-menu {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 2px;
    cursor: pointer;
    z-index: 100;

}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #FFF;
    margin: 8px 0;
    transition: all $mid ease;
    &.close {
        position: absolute;
        top: 100%;
        left: 50%;
        width: auto;
        height: auto;
        background-color: transparent;
        text-transform: uppercase;
        font-weight: bold;
        padding-right: 20px;
        box-sizing: border-box;
        transform: translate(-50%, 50%) rotate(270deg);
        opacity: 0;
        transition: opacity $mid ease;
    }
}

/***** Slide menu ****/
.slide-menu {
    display: none;
    position: fixed;
	background: $primary;
    width: 240px;
	top: 0;
	border-left: 1px solid $shade;
	right: -241px;
    height: 100%;
	overflow-y: scroll;
	z-index: 1000;
	.menu-title {
        padding: 18px;
        padding-top: 20px;
        font-size: 1.3em;
        color: #fff;
        margin:0;
        border-bottom: 1px solid $shade;
        font-weight:bold;
        cursor: pointer;
    }
    nav {
        li {
            width: 100%;
            line-height: 1;
            a {
                padding: 1em;
                padding-left: 1.5em;
                transition: background-color $mid ease-in-out;
	            border-bottom: 1px solid $shade;
	            &:hover {
	                background-color: $shade;
	                text-decoration: none;
	            }
            }
            .title {
                font-size: 18px;
            }
            &.top {
                margin: 0px;
                > a {
                    padding: 1em;
                }
            }
            &.has-sub {
                position: relative;
                display: block;
                > a {
                    position: relative;
                    border-bottom: 1px solid $shade;
                    border-right: 1px solid $shade;
                    margin-right: 50px; /* space for drop */
                    &::after { /* border below drop */
                        position: absolute;
                        content: "";
                        display: block;
                        left: 100%;
                        bottom: -1px;
                        background-color: $shade;
                        width: 50px;
                        height: 1px;
                        z-index: 100;
                    }
                }
                &.expand-active {
                    > a {
                        border-color: #FFF;
                        &::after {
                            background-color: #FFF;
                        }
                    }
                    > .expand i {
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                    > ul {
                        display: block;
                    }
                }
            }
            li {
                a {
                    border-color: #FFF;
                    &::after {
                        background-color: #FFF;
                    }
                }
            }
            > ul {
                display: none;
                background-color: mix($primary, #FFF, 85%);
                ul {
                    background-color: mix($primary, #FFF, 75%);
                }
            }
        }
        .expand {
            position: absolute;
            top: 0;
            right: 0;
            height: 54px;
            width: 50px;
            cursor: pointer;
            i {
                content:"";
                display: block;
                height: 100%;
                width: 100%;
                background: url('../assets/menu-drop.svg') no-repeat center center;
                background-size: 30%;
                -webkit-transition: -webkit-transform 500ms ease;
                transition: -webkit-transform 500ms ease;
                transition: transform 500ms ease;
                transition: transform 500ms ease, -webkit-transform 500ms ease;
            }
        }
    }
    .site-links {
        padding-top: 13px;
        padding-left: 13px;
    }
}

/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.site-content-menu-slide {
    overflow-x: hidden;
    position: relative;
    right:0;
}
.slide-menu-active {
/* desktop */
    header {
        background-color: $primary;
        .top-slide {
            max-height: 100vh;
            opacity: 1;
        }
    }
    #mobile-menu {
        > span {
            &.one {
                -webkit-transform: rotate(-45deg) translate(-8px, 8px);
                transform: rotate(-45deg) translate(-8px, 8px);
            }
            &.two {
                opacity: 0;
            }
            &.three {
                -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
                transform: rotate(45deg) translate(-8px, -8px);
            }
            &.close {
                opacity: 1;
            }
        }

    }
/* mobile */
    .slide-menu {
        right: 0px;
    }
}


/*============ footer ============*/
.banners-footer {
    a.banner:not([href]) {
        /* hide read more */
        .post_content {
            display: none;
        }
    }
    .banner-content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        justify-content: space-evenly;
        padding: 30px;
        padding-top: 4%;
        > span {
            display: block;
            padding-right: 70%;
            margin: 1rem 0px;
        }
        .banner-name {
            font-size: 1.6em;
            font-weight: 600;
        }
        .banner-title {
            text-transform: uppercase;
        }
        .banner-caption {
            font-size: 0.75em;
        }
    }
}
footer {
    padding-top: 4%;
    .flex {
        position: relative;
        padding-left: 280px; /* logo */
        justify-content: space-evenly;
    }
    .logo {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .col {
        //width: 32%;
        width: 48%;
        padding-left: 4%;
        box-sizing: border-box;
        border-left: 1px solid #FFF;
        &.about-pg {
            p {
                font-size: 0.75em;
                font-weight: 300;
                &:last-of-type {
                    margin-top: 1.5em;
                }
            }
            padding-right: 4%;
        }
        &.menu {
            //flex-grow: 1;
            padding-right: 0px;
        }
    }
    h3 {
        margin-top: 0px;
    }
    .site-links {
        padding-top: 15px;
    }
    nav li.top {
        margin-top: 0px;
    }
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 4%;
    width: 100%;
    box-sizing: border-box;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}


/*============ Home ============*/
/* home banner */
.banners-home {
    .banner-stage {
        /* inherits flex column + position relative */
        height: 100vh;
        min-height: 600px;
        padding: 0px;
        &:after {
            @include banner-line;
            margin-left: calc((100% - 1260px)/2); /* align with general contentwrap */
            margin-top: -5vw;
        }
    }
    .banner {
        @include darken_before($mid-grey, absolute);
        justify-content: flex-end;
        padding-top: 250px; /* header */
        padding-bottom: 100px; /* banner-nav */
    }
    .banner-content {
        width: 604px; /* 48% */
        width: calc(1260px * 0.48);
        margin-left: calc((100% - 1260px)/2); /* align with general contentwrap */
        .banner-name,
        .banner-title {
            display: inline;
            font-size: 2em;
            font-weight: 600;
        }
        .banner-title {
            color: $primary;
        }
        .banner-caption {
            display: block;
            margin-top: 1.5em;
            > span {
                @include button;
            }
        }
    }
/* hide caption if no link */
    a.banner:not([href]){
        .banner-content .banner-caption {
            > span {
                display: none;
            }
        }
    }
}

/***** page content area ******/
h2.title {
    font-size: 2em;
    line-height: 1;
}
.t-home {
    h2 {
        font-size: 2em;
    }
    .page-banner {
        &.fallback {
            min-height: 0px;
        }
        &.has-graphic {
            min-height: 300px;
            padding-top: 22%;
            box-sizing: border-box;
        }
        &.has-banners {
            display: block; // undo flex
            padding-top: 0px;
            /* height set by banners */
        }
        .flex {
            width: 50%;
        }
        .contentwrap {
            .flex {
                position: static;
                &::after {
                    top: 0px;
                    bottom: auto;
                }
            }
        }
    }
    #content {
        padding-bottom: 0px;
        h2.title,
        h2.subtitle {
            margin-top: 0px;
        }
        .flex {
            position: relative;
            &.has-icon {
                padding-right: 52%
            }
            .square.icon {
                position: absolute;
                top: 0px;
                left: 52%;
            }
        }
        .col {
            flex-grow: 1;
            > a {
                @include flex_apart;
                flex-direction: column;
                &:hover {
                    .button {
                        background-color: $shade;
                    }
                }
            }
        }
        .page-content {
            position: relative;
            padding-top: 6%;
            padding-top: 6vw; /* for banner line*/
            /* bg tint */
            &:before {
                content:"";
                position: absolute;
                display: block;
                top: 55%;
                bottom: 0px;
                left: 0px;
                width: 100%;
                background-color: $lightest;
                z-index: 0;
            }
            .contentwrap {
                padding-bottom: 0px;
                /* short vertical line */
                &::after {
                    content:"";
                    position: relative;
                    display: block;
                    width: 100px;
                    height: 100px;
                    border-right: 2px solid $primary;
                    margin: 0px;
                    margin-bottom: -30px;
                    margin-right: auto;
                }
            }
        }
        nav {
            position: relative;
            /* bg tint */
            &:before {
                content:"";
                position: absolute;
                display: block;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 50%;
                background-color: $lightest;
                z-index: 0;
            }
            .contentwrap {
                padding-bottom: 5vw; /* line */
                /* vertical line */
                &:after {
                    @include banner-line;
                    left: auto;
                    height: 13vw; /* taller */
                    right: 200px;
                    margin-top: -4vw;
                    z-index: 1;
                }
            }
            h2.title {
                margin-top: 30px;
            }
        }
        .about-pg {
            margin-top: 5%; /* for icon */
            .flex.has-icon {
                padding-right: 0px;
                padding-left: 52%;
                min-height: 600px;
            }
            .square.icon {
                top: -10vw;
                left: auto;
                right: 52%;
            }
        }
    }
}

/**** testimonial scroller ****/
.scroll-wrap {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    overflow: hidden;
}
div.testimonial {
    border-top: 1px solid $primary;
    .teaser {
        font-size: 1.6em;
        line-height: 1.12;
        font-weight: 300;
        font-style: italic;
        margin: 0px;
        padding-bottom: 4%;
    }
    p.intro {
        margin: 2px 0px;
    }
}
.scroller {
    position: relative;
    width: 100%;
    min-height: 300px;
    white-space: nowrap;
    .item {
        position: absolute;
        top: 0px;
        left: 0px;
        display: inline-block;
        color: $primary;
        vertical-align: top;
        white-space: normal;
        width: 100%;
        padding: 4%;
        padding-bottom: 20px;
        border-top: 0px;
        box-sizing: border-box;
        opacity: 0;
        transition: opacity $mid ease-in-out;
        &.active {
            opacity: 1;
        }
        .teaser {
            margin: 0px;
        }
    }
}
.scroll-nav {
    visibility: hidden; /* shown with JS */
    padding: 20px 4%;
    padding-bottom: 40px;
    .nav-item {
        @include dots_dark;
        &:first-of-type {
            margin-left: 0px;
        }
        &:last-of-type {
            margin-right: 0px;
        }
    }
}

/**** latest news ****/
.latest-news {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 40%;
        bottom: 0px;
        width: 100%;
        background-color: $lightest;
    }
}

/*============ page ============*/
#content {
    padding-bottom: 8%;
}
h2.subtitle {
    margin-top: 0px;
}
.page-content {
    padding-top: 6%;
    padding-top: 4.5vw; /* page-banner vertical-line */
}
.page-banner {
    &.fallback {
        background-color: $shade;
        .contentwrap {
            padding: 30px;
            .flex:after {
                height: 10vw;
            }
        }
    }
    .flex {
        justify-content: flex-start;
        h1, p  {
            display: inline-block;
            box-sizing: border-box;
            padding: 2% 0px;
            margin: 0px;
        }
        h1 {
            padding-right: 5%;
        }
        p {
            font-weight: 300;
            width: 48%;
            padding-top: 3.5%;
        }
    }
}

.gallery {
    padding-top: 5%;
    .kmsgallery {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 15px;
        row-gap: 15px;
        .kmsgallery-thumbnail-image {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 0px;
            padding-bottom: 100%;
            margin: 0px;
            &::after {
                content:"";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background-color: rgba($primary, 0.53);
                background-image: url('../assets/gallery-icon.svg');
                background-size: 40%;
                background-repeat: no-repeat;
                background-position: center;
                opacity: 0;
                transition: opacity $quick ease-in-out;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
            &:nth-of-type(7n){
                grid-column: span 2;
                grid-row: span 2;
            }
        }
    }
}

/*============ Contact ============*/
.t-contact {
    .page-content {
        .flex {
            padding-bottom: 5%;
            .page-body {
                padding-right: 4%;
            }
        }
    }
}
.tel {
    font-weight: bold;
    white-space: no-wrap;
}
.opening-hours {
    display: block;
}
.opening-days {
    font-weight: 600;
    .opening-to::after {
        content:":";
    }
}
.line2 {
    display: block;
}
.contact-details,
.details { /* used in shared item as well */
    display: table;
    p.item,
    div.item {
        display: table-row;
        font-size: 1em;
        > * {
            display: table-cell;
            padding-bottom: 10px;
        }
        &.socials,
        &.address {
            > * {
                padding-top: 20px;
            }
        }
    }
    .label {
        vertical-align: top;
        color: $primary;
        font-weight: bold;
        text-transform: capitalize;
        padding-right: 20px;
    }
}

/** map **/
.map-wrap {
    position: relative;
    background-color: #d3d3d3;
    padding-top: 30%;
    margin-top: 5%;
}
.kms-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
#map,
#map-click {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#map {
    display: block;
}

/*============ shared index  ============*/
/* content item generic */
div.item {
    font-size: 18px;
    h3.title {
        font-size: 1.33em;
        font-weight: normal;
        text-transform: uppercase;
    }
    .teaser {
        margin: 1em 0px;
    }
}
/* top item */
.feature .item,
.title-block { /** item.html top section **/
    .square.icon {
        order: 1; /* push right */
        width: 59%;
        /* don't stretch, keep square */
        align-self: flex-start;
        &:hover {
            .hover {
                opacity: 1;
            }
        }
    }
    .txt {
        display: inline-block;
        width: 36%;
        span.button {
            margin-top: 1em;
        }
    }
}
.feature .item {
    > a {
        @include flex_apart;
        &:hover {
            .txt .button {
                background-color: $shade;
            }
            div.icon .hover {
                opacity: 0;
            }
        }
    }
    .txt::before {
        content:"Featured";
        font-weight: 600;
        font-size: 2.2em;
        color: $primary;
    }
}
.t-news .feature .item {
    .txt::before {
        content:"Latest";
    }
}

/* item list */
.list { /* space-between */
    .item {
        display: inline-block;
        vertical-align: top;
        width: 30%;
        padding: 2% 0px;
        box-sizing: border-box;
        /** icons defined in author styles > backgrounds section above **/
    }
}

/** pagination **/
.pages {
    text-align: center;
    padding-top: 40px;
    .button {
        min-width: 40px;
        line-height: 40px;
        padding: 0px;
        &.active {
            color: $shade;
            background-color: $lighter;
            &:hover {
                background-color: $lighter;
            }
        }
    }
}

/*============ Testimonials  ============*/
.t-testimonials {
    .list {
        display: block;
        .item {
            width: 100%;
            padding: 4% 0px;
            padding-top: 2%;
            div {
                margin-bottom: 3%;
            }
        }
        > div:last-of-type {
            border-bottom: 1px solid $primary;
        }
    }
}

/*============ shared item  ============*/
.title-block {
    padding-bottom: 5%;
    h2.title {
        font-size: 1.3em;
        text-transform: uppercase;
        font-weight: normal;
    }
    .square.icon {
        margin-top: -10%;
        margin-top: -10vw;
    }
}
.details {
    font-size: 18px;
    margin-bottom: 10px;
    .site-link img {
        max-height: 20px;
    }
}
// item content
.t-item .page-body {
    border-top: 1px solid $primary;
    margin-top: 3%;
    &.empty {
        border-top: none;
    }
}

section.more-links {
    border-top: 1px solid $primary;
    padding-bottom: 0px;
    margin-top: 8%;
}

/*============ Product item (service) ============*/
.t-product {
    .item.price {
        .large {
            font-size: 1.2rem;
        }
    }
    form.buy {
        border-top: 1px solid $primary;
        textarea {
            min-height: 90px;
        }
        span.blk {
            color: #000;
        }
        .wtfield-HiddenField.wtwidget-CustomHTMLWidget {
            hr {
                margin: 10px 0px;
            }
            h3 {
                font-size: 1em;
                margin: 0px;
            }
        }
        .wtfield-StripeField {
            margin-bottom: 20px;
        }
    }
}
.js {
    form.buy {
        .purchase-form {
            display: none;
        }
    }
    p.no-js {
        display: none;
    }
}
#card-element {
    color: $txt;
    height: 45px;
    line-height: 45px;
    padding: 10px;
    background-color: #f1f1f1;
    box-sizing: border-box;
    border: 1px solid $shade;
}
#card-errors {
    color: #000;
    font-weight: normal;
}

/**** purchase pages ****/
.t-purchase {
    header {
        position: relative;
        background-color: $shade;
    }
    #content {
        padding: 40px 0px;
        padding-bottom: 5%;
    }
}


/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (min-width: 1900px) {
/** reduce page banners on large screens **/
	.page-banner {
		.contentwrap {
			padding-top: 2%;
		}
	}
}

@media only screen and (max-width: 1500px) {
/** home **/
    .t-home {
        section {
            h2 {
                margin-top: 0px;
            }
        }
        #content  {
            .flex.has-icon {
                padding-right: 0px;
                align-content: flex-start;
                .square.icon {
                    position: relative;
                    left: 0px;
                    width: 48%;
                    align-self: flex-start;
                }
            }
            .page-content {
                .square.icon {
                    order: 1; /* push right */
                }
            }
            .col {
                flex-basis: 48%;
                flex-grow: 0;
            }
            .about-pg {
                padding-bottom: 3%;
                .flex.has-icon {
                    padding-left: 0px;
                    min-height: 0px;
                    align-content: flex-start;
                }
                .square.icon {
                    top: 0px;
                    margin-top: -10%;
                    margin-top: -13vw;
                }
                .col {
                    align-self: flex-start;
                }
            }
        }
    }
/** footer **/
    .banners-footer {
        .banner-navigation-body {
            padding: 30px;
        }
    }
}
@media only screen and (max-width: 1300px){
/** home **/
    .banners-home {
        .banner-stage {
            height: 70vh;
            &:after { /* vertical line */
                left: 130px;
				margin-left: 0px;
            }
		}
        .banner-content {
            padding-left: 40px;
            margin-left: 0px;
        }
    }
    .t-home  {
        #content {
            .flex.has-icon .square.icon {
                margin-right: -30px; /* touch edge */
            }
            .about-pg {
                .flex.has-icon .square.icon {
                    margin-left: -30px; /* touch edge */
                }
            }
        }
    }
/** footer **/
    .banners-footer .banner-content > span {
        padding-right: 52%;
        margin: 10px 0px;
    }
    footer {
        .flex {
            padding-left: 0px;
        }
        .logo {
            position: static;
            padding-right: 4%;
        }
        .col {
            &.about-pg {
                width: auto;
                flex-grow: 1;
                flex-basis: 60%;
                padding-bottom: 2%;
            }
            &.menu,
            &#subscribe {
                padding-top: 2%;
                border-top: 1px solid #FFF;
            }
            &.menu {
                display: none;
                border-left: 0px;
                padding-left: 0px;
            }
        }
    }
}
@media only screen and (max-width: 1200px){
    /** home **/
    .banners-home  {
        .banner-content {
            width: 60%;
            .banner-name,
            .banner-title {
                font-size: 1.5em;
                line-height: 1;
            }
       }
    }
}

@media only screen and (max-width: 960px) {
/** header **/
    header .top-slide {
        .flex {
            flex-direction: column;
        }
        .col {
            width: 100%;
        }
        .list {
            display: none;
        }
    }
/** generic **/
    .col {
        flex-grow: 1;
        padding: 1% 0px;
    }
    .page-banner {
        .flex {
            p {
                flex-grow: 1;
            }
        }
    }
    section {
        padding: 40px 0px;
    }
/** home **/
    .banners-home {
        .banner-content {
            width: 100%;
        }
    }
    .t-home {
        #content {
            .page-content {
                .flex.has-icon {
                    display: block; /* no flex */
                    .square.icon {
                        float: right;
                        margin-left: 30px;
                        margin-bottom: 5px;
                    }
                    .page-body.col {
                        display: inline;
                    }
                    /* clear float */
                    &::after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        clear: both;
                    }
                }
                .col {
                    flex-grow: 1;
                    width: 100%;
                }
            }
            nav {
                &::before {
                    height: 40%;
                }
                h2.title {
                    margin-bottom: 5px;
                }
                > ul {
                    flex-direction: column;
                }
                li {
                    display: block;
                    width: 100%;
                    padding-top: 2%;
                    &:nth-of-type(even){
                        .menu-icon {
                            order:0; /* push right */
                        }
                    }
                    a {
                        flex-direction: row; /* so we can still reorder */
                        flex-wrap: wrap;
                        justify-content: space-evenly;
                        justify-content: space-between;
                        .title {
                            width: 100%;
                        }
                    }
                    .menu-icon,
                    .txt {
                        width: 48%;
                    }
                    .menu-icon {
                        order: 1; /* push right */
                    }
                    .txt {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }
        }
    }
/*** reduce lists ***/
    /* home */
    .latest-news {
        .list > div:last-of-type {
            display: none;
        }
    }
    /* index + more-links */
    .list {
        .item {
            width: 48%;
        }
    }
/** shared item **/
    .title-block {
        form .half {
            flex-direction: column;
            p {
                width: 100%;
                &:nth-of-type(odd),
                &:nth-of-type(even) {
                    padding: 0px;
                }
            }
        }
    }
    .more-links .list {
        > div:last-of-type {
            display: none;
        }
    }
/** footer **/
    .banners-footer {
        .banner-content > span {
            padding-right: 0px;
        }
        .banner-navigation-body {
            padding: 20px 30px;
        }
    }
}

@media only screen and (max-width: 760px) {
/* mobile activate */
    .slide-menu {
        display:block;
    }
    .slide-menu-active {
        header {
            background-color: transparent;
            .top-slide {
                max-height: 0px;
                opacity: 0;
            }
        }
        .site-content-menu-slide {
            right: 240px;
        }
    }
/* generic */
    .banner-stage {
        min-height: 300px;
    }
    .banner-navigation-body {
        padding: 20px 30px;
    }
/** page banner **/
    header .contentwide {
        padding-top: 20px;
        padding-right: 20px;
    }
    .page-banner {
        padding-top: 180px;
        .flex {
            &:after { /* vertical line */
				display: none;
            }
            p {
                font-size: 0.75em;
            }
        }
    }
    .page-content {
        padding-top: 20px;
    }
/** home **/
	.t-home .page-banner {
		&.has-graphic {
			min-height: 300px;
			padding-top: 60%;
		}
	}
    .banners-home {
        .banner-stage {
            height: 60vh;
			min-height: 500px;
            /* vertical line */
            &:after {
                display: none;
            }
        }
    }
    .t-home #content  {
        /* match the padding to vertical line */
        .page-content {
            padding-top: 40px; /* banner line space */
            .contentwrap {
                /* second vertical line */
                &::after {
                    display: none;
                }
            }
        }
        nav .contentwrap {
            padding-top: 10px;
            /* third vertical line */
            &::after {
                display: none;
            }
        }
        .about-pg {
            padding-top: 20px;
            margin-top: 60px; /* gap for line */
            .flex.has-icon  {
                display: block;
                .square.icon {
                    float:left;
                    margin-top: -75px;
                    margin-right: 30px;
                    margin-bottom: 5px;
                }
                .col {
                    display: inline;
                    > a {
                       display: inline;
                    }
                }
                /* clear float */
                &:after {
                    content:"";
                    display: block;
                    width: 100%;
                    height: 1px;
                    clear: both;
                }
            }
        }
    }
    .latest-news {
        /* tinted bg */
        &::before {
            top: 25%;
        }
    }
/** contact **/
    .t-contact {
        .page-content .flex {
            flex-direction: column;
            .col {
                width: 100%;
            }
            .page-body {
                padding-right: 0px;
            }
        }
    }
    .contact-details .label {
        padding-right: 10px;
    }
    .map-wrap {
        min-height: 300px;
        box-sizing: border-box;
        margin-top: 40px;
    }
    #map-click {
        display: block;
    }
/** shared index + shared item top **/
    .feature .item,
    .title-block {
        .square.icon {
            width: 48%;
            margin-left: 2%;
            z-index:1;
        }
        .txt {
            flex-grow: 1;
            width: auto;
            flex-basis: 40%;
        }
    }
    .title-block {
        .square.icon {
            margin-top: -10vw;
        }
        h2.title {
            font-size: 1em;
        }
    }
/** footer **/
    footer {
        .logo {
            padding: 0px;
            padding-left: 30px; /* balance out the wide t */
            margin: 0px auto;
        }
        .col {
            text-align: center;
            padding: 0px;
            border: 0px;
            &.about-pg {
                width: 100%;
                flex-basis: 100%;
                border-left: 0px;
                text-align: center;
                padding: 0px;
                .about {
                    display: none;
                }
            }
            &.menu {
                display: none;
            }
            &#subscribe {
                padding-top: 30px;
                margin-top: 30px;
                input, label.float {
                    width: 100%;
                    text-align: center;
                    padding-left: 0px;
                }
            }
        }
        .site-links {
            padding-top: 30px;
        }
    }
}
@media only screen and (max-width: 600px){
/* page banners */
    .page-banner {
        .flex {
            flex-direction: column;
            p {
                width: 100%;
                padding: 0px;
            }
        }
    }
    .page-content {
        padding-top: 10px;
    }
/** home page **/
    .banners-home {
        .banner-content {
            .banner-name,
            .banner-title {
                font-size: 1.2em;
            }
        }
    }
    /* stack icons */
    .t-home #content {
        .page-content,
        .about-pg {
            .flex.has-icon .square.icon {
                display: block;
                width: 80%;
                min-width: 200px;
                max-width: 100%;
                float: none;
                margin: 0px auto;
                margin-bottom: 20px;
            }
        }
        .page-content::before {
            top: 30%;
        }
        nav {
            padding-bottom: 40px;
            &::before {
                height: 20%;
            }
            li {
                width: 80%;
                min-width: 200px;
                max-width: 100%;
                margin: 0 auto;
                a {
                    display: block;
                }
                .menu-icon,
                .txt {
                    display: block;
                    text-align: center;
                    width: 100%;
                    margin: 0px auto;
                }
                .menu-icon {
                    float: none;
                    margin-bottom: 20px;
                }
            }
        }
        .about-pg {
            padding: 0px;
            text-align: center;
            .flex.has-icon  {
                .square.icon {
                    width: 80%;
                    min-width: 200px;
                    max-width: 100%;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    margin-top: -60px;
                }
            }
        }
    }
/** content galleries **/
    .gallery .kmsgallery {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }
/** contact **/
    .banners-footer .banner-content {
        text-align: center;
        align-items: center;
    }
    form .half {
        flex-direction: column;
        p {
            width: 100%;
            &:nth-of-type(odd),
            &:nth-of-type(even){
                padding: 0px;
            }
        }
    }
    .contact-details {
        display: block;
        display: flex;
        flex-wrap: wrap;
        .item {
            display: inline-block;
            min-width: 48%;
            flex-grow: 1;
            margin-bottom: 10px;
            > * {
                display: block;
            }
            &.address,
            &.socials {
                > * {
                    padding-top: 5px;
                    &.site-links {
                        padding: 0px;
                    }
                }
            }
        }
        .label {
            padding: 0px;
            padding-bottom: 0px;
        }
    }
/** shared index + shared item top **/
    .list .item {
        width: 80%;
        min-width: 200px;
        max-width: 100%;
        margin: 0 auto;
    }
    section.feature {
        padding-bottom: 20px;
    }
    .feature .item,
    .title-block {
        .square.icon {
            order: 0;
            margin-left: 0px;
            width: 400px;
            max-width: 100%;
        }
        .txt::before {
            display: none;
        }
        > a {
            flex-direction: column;
        }
    }
    .feature .item {
        .square.icon {
            width: 100%;
            margin: 0 auto;
        }
        &::before {
            content:"Latest";
            display: block;
            text-align: left;
            font-weight: 600;
            font-size: 2.2em;
            color: $primary;
            margin-bottom: 20px;
        }
        .txt {
            position: relative;
            background-color: $lightest;
            padding-bottom: 30px;
            &::before,
            &::after {
                content:"";
                position: absolute;
                top: 0px;
                bottom: 0px;
                display: block;
                background-color: inherit;
                width: 30px;
                height: 100%;
                z-index: -1;
            }
            &::before {
                right: 100%;
            }
            &::after {
                left: 100%;
            }
        }
    }
}
@media only screen and (max-width: 500px){
/* stick mobile icon */
	#mobile-menu {
		position: absolute;
		top: 10px;
		right: 18px;
	}
/* reduce text size */
    body {
        font-size: 18px;
    }
    nav li .title {
        line-height: 1;
    }
/* break links */
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
    /* override on mobile menu and banners */
    .slide-menu a,
    .banners a {
        word-break: normal;
        hyphens: none;
    }
/** banners **/
    .page-banner {
        &::after {
            width: 10px;
        }
        /* reduce text size to defaults */
        .contentwrap .flex {
            h1, h2 { /* a touch above browser default */
                font-size: 1.6em;
            }
        }
    }

}
@media only screen and (max-width: 300px){
/* stop overflow on mobiles */
    form button,
    .button {
        width: 100%;
        padding: 15px 10px;
        min-width: 0px;
    }
    .tel {
        white-space: normal;
    }
/* form */
    form > p:last-of-type {
        margin-top: 10px;
    }
/** content galleries **/
    .gallery .kmsgallery {
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
    }
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}